import React from 'react';
import { CircularProgress, Backdrop } from '@material-ui/core';

interface LoadingOverlayProps {
  loading: boolean;
};

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ loading }) => (
  <Backdrop
    open={loading}
    style={{
      zIndex          : 2147483647,
      color           : '#fff',
      backgroundColor : 'rgba(0, 0, 0, 0.5)',
    }}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);
