import { Grid, InputLabel }                                                    from '@material-ui/core';
import { FastField, FormikProps }                                              from 'formik';
import React, { FC, RefObject, useMemo }                                       from 'react';
import { FamilyMembersSelector }                                               from '../../../formFields/FamilyMembersSelector';
import { PhoneInputField }                                                     from '../../../formFields/PhoneInputField';
import { SelectField }                                                         from '../../../formFields/SelectField';
import { TextInputField }                                                      from '../../../formFields/TextInputField';
import { WithCategorySelection }                                               from '../../../hocs/withCategorySelection';
import { useFetchCategories }                                                  from '../../../queries/categories';
import { familyRoles }                                                         from '../../../queries/family';
import { UploadEntityFormLabel, UploadEntityFormRow, UploadEntityFormSpacing } from '../../documents/styles';
import { GridDivider }                                                         from '../../layout/GridDivider';
import { ModalFooterActions }                                                  from '../shared/ModalFooterActions';
import { IContact }                                                            from './queries';
import { LoadingOverlay }                                                      from '../../LoadingOverlay';

interface IAddContactModalFormProps extends FormikProps<IContact> {
  familyId?: string;
  loading: boolean;
  formikRef: RefObject<FormikProps<IContact>>;
}

export const UploadContactModalForm: FC<IAddContactModalFormProps> = ({
  familyId,
  loading,
  formikRef,
  ...form
}) => {
  const { values, setFieldValue, handleSubmit } = form;
  const { data: categories, isLoading: loadingCategories } = useFetchCategories(familyId);

  const Form = WithCategorySelection(({ categoriesDropdown }) => (
    <>
      <UploadEntityFormSpacing onSubmit={handleSubmit}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <FastField
              label="Title/Nickname"
              name={'title'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <GridDivider margin="0 0 -10px" />

          <Grid
            item
            xs={12}
            sm={6}
          >
            <FastField
              label="First Name"
              name={'firstName'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <FastField
              label="Last Name"
              name={'lastName'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />
          </Grid>

          <GridDivider margin="0 0 -10px" />

          <Grid
            item
            xs={12}
            sm={6}
          >
            <InputLabel variant="outlined">Category</InputLabel>

            { categoriesDropdown }
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <InputLabel variant="outlined">Role</InputLabel>

            <FastField
              name="role"
              options={familyRoles}
              component={SelectField}
            />
          </Grid>

          <GridDivider margin="0 0 -10px" />

          <Grid
            item
            xs={12}
          >
            <UploadEntityFormRow>
              <UploadEntityFormLabel width="auto">Tag</UploadEntityFormLabel>

              <FamilyMembersSelector
                multiple
                name="tagged"
              />
            </UploadEntityFormRow>
          </Grid>

          <GridDivider margin="0 0 -10px" />

          <Grid
            item
            xs={12}
          >
            <FastField
              fullWidth
              label="Office Address"
              name={'officeAddress'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />

            <GridDivider margin="15px 0 -15px" />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FastField
              fullWidth
              label="Home Address"
              name={'homeAddress'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />

            <GridDivider margin="15px 0 -15px" />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FastField
              fullWidth
              label="Email Address"
              name={'email'}
              inputProps={{ maxLength: 100 }}
              component={TextInputField}
            />

            <GridDivider margin="15px 0 -15px" />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <UploadEntityFormLabel width="auto">Office Number</UploadEntityFormLabel>
            <FastField
              fullWidth
              label="Office Number"
              name={'officeNumber'}
              component={PhoneInputField}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <UploadEntityFormLabel width="auto">Home/Cell Number</UploadEntityFormLabel>

            <FastField
              fullWidth
              label="Home/Cell Number"
              name={'homeNumber'}
              component={PhoneInputField}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FastField
              multiline
              fullWidth
              rows={4}
              label="Notes"
              name={'notes'}
              component={TextInputField}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <ModalFooterActions
              formikRef={formikRef}
              disabled={loading}
            >
              { loading ? 'Uploading' : values?.id ? 'Edit' : 'Add' } Contact
            </ModalFooterActions>
          </Grid>
        </Grid>
      </UploadEntityFormSpacing>
      <LoadingOverlay loading={loading} />
    </>
  ));

  return useMemo(() => (
    <Form
      values={values}
      familyId={familyId}
      categories={categories}
      loading={loadingCategories}
      setFieldValue={setFieldValue}
    />
  ), [ loading, categories ]);
};
