import { FieldProps, getIn } from 'formik';
import React, { FC }         from 'react';
import PhoneInput            from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface IPhoneFieldOwnProps extends FieldProps {
  id?: string;
}

export const PhoneInputField: FC<IPhoneFieldOwnProps> = ({
  field,
  form,
  ...props
}) => {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  return (
    <div>
      <PhoneInput
        {...field}
        country={'us'}
        inputStyle={{ width: '100%', margin: '10px 0 10px 0' }}
        placeholder="Enter phone number"
        enableLongNumbers={true}
        inputProps={{
          maxLength: 20,
        }}
        onChange={(value) => {
          form.setFieldValue(field.name, `+${value}`);
        }}
      />
      {touched && error ? (
        <div style={{ color: 'red', marginTop: '5px' }}>{error}</div>
      ) : null}
    </div>
  );
};
