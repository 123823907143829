import { FC, useCallback, useMemo, useState } from 'react';
import moment, { Moment }                     from 'moment';
import styled                                 from 'styled-components';
import { Create, Delete }                     from '@material-ui/icons';
import { Grid }                               from '@material-ui/core';
import { EventDetailsModal }                  from '../../../shared/components/modals/UploadEvent/EventDetailsModal';
import { IEvent, useDeleteEvent }             from '../queries';
import { ChangeEventModal }                   from './ChangeEventModal';
import { queryClient }                        from '../../../App';
import { EVENTS }                             from '../../MyFamilyCore/events/queries';
import { UploadEventModal }                   from '../../../shared/components/modals/UploadEvent/UploadEventModal';
import { AlertDialog }                        from '../../../shared/components/modals/AlertDialog';
import { IUploadEvent, useUpdateEvent }       from '../../../shared/components/modals/UploadEvent/queries';
import { ERecurrence }                        from '../../../shared/components/modals/shared';
import {
  EventBadgeTime,
  EventBadgeTitleWrapper,
  EventBadgeWrapper,
}                                             from './EventBadge';

const DeleteEventContent = styled.div`
  word-break: break-all;
`;

const StyledGrid = styled(Grid)`
  justify-content: space-between;
  padding: 15px 0 32px;
`;

const Title = styled.span`
  font-size: 12px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  width: 100%;
`;

const EventInfo = styled.div`
  display: flex;
  font-size: 10px;
  color: #656565;
  padding-left: 10px;
`;

const Actions = styled.span`
  .MuiSvgIcon-root {
    fill: #979797;
    cursor: pointer;
	}
`;

interface IEventBadgeProps {
  day: Moment | null;
  event: IEvent;
  familyId?: string;

  handleClose(): void;
}

export enum EOccurrenceType {
  ThisEvent = "THIS_EVENT",
  AllEvents = "ALL_EVENTS",
}

export enum EEventBadgeModalType {
  EventDetails,
  Delete,
  UpdateEvent,
  ConfirmUpdate,
}

export const EventBadgeForPopover: FC<IEventBadgeProps> = ({
  day,
  event,
  familyId,
  handleClose,
}) => {
  const [ occurence, setOccurence ]                       = useState<EOccurrenceType>(EOccurrenceType.ThisEvent);
  const [ eventBadgeModalState, setEventBadgeModalState ] = useState<EEventBadgeModalType | null>(null);
  const isRecurringEvent = event.recurs !== ERecurrence.NoRecurrence;

  const { mutate: deleteEvent, isLoading: isDeletingEvent } = useDeleteEvent(familyId);
  const { mutate: updateEvent } = useUpdateEvent();

  const onDeleteEvent = () => {
    if (occurence === EOccurrenceType.AllEvents || !isRecurringEvent) {
      deleteEvent(event.id, {
        onSuccess: () => {
          setEventBadgeModalState(EEventBadgeModalType.Delete);
          queryClient.invalidateQueries(EVENTS);
          handleClose();
        },
      });
    } else {
      const data = {
        ...event,
        familyId,
        categoryId         : event.category.id,
        eventExceptionDays : [...event.eventExceptionDays, day?.format()],
      } as IUploadEvent;

      updateEvent({ data });
      handleClose();
    }
  };

  const closeModal = useCallback(() => {
    setEventBadgeModalState(null);
    handleClose();
  }, []);

  const renderDeleteModal =
    (!isRecurringEvent) ? (
      <AlertDialog
        open
        dialogWidth="350px"
        title="Are you sure you want to delete this event?"
        content={
          <DeleteEventContent>
            Event "{ event?.title }"
          </DeleteEventContent>
        }
        submitButtonDisabled={isDeletingEvent}
        handleSubmit={() => onDeleteEvent()}
        handleCancel={() => setEventBadgeModalState(null)}
      />
    ) : (
      <ChangeEventModal
        open
        title="Remove recurring event?"
        dialogWidth="427px"
        value={occurence}
        setValue ={setOccurence}
        submitButtonDisabled={isDeletingEvent}
        handleSubmit={() => onDeleteEvent()}
        handleCancel={() => setEventBadgeModalState(null)}
      />
    );

  const renderModal = () => {
    switch(eventBadgeModalState) {
      case EEventBadgeModalType.EventDetails:
        return (
          <EventDetailsModal
            open
            event={event}
            familyId={familyId}
            onClose={closeModal}
          />
        );
      case EEventBadgeModalType.Delete:
        return renderDeleteModal;
      case EEventBadgeModalType.UpdateEvent:
        return (
          <UploadEventModal
            open
            familyId={familyId}
            event={event}
            singularEventDate={isRecurringEvent && occurence === EOccurrenceType.ThisEvent ? day : null}
            onClose={closeModal}
          />
        );
      case EEventBadgeModalType.ConfirmUpdate:
        return (
          <ChangeEventModal
            open
            title="Edit calendar event?"
            dialogWidth="427px"
            value={occurence}
            setValue ={setOccurence}
            handleSubmit={() => setEventBadgeModalState(EEventBadgeModalType.UpdateEvent)}
            handleCancel={() => setEventBadgeModalState(null)}
          />
        );
      default:
        return null;
    }
  };

  const eventTime = useMemo(() => {
    if (event.allDay) {
      return <span>All Day &#183; </span>;
    }

    if (event.recursStartTime) {
      const startTime = moment.utc(event.recursStartTime).format('h:mm A');
      const endTime = event.endDate ? moment.utc(event.endDate).format('h:mm A') : '';

      return <EventBadgeTime>{startTime} {endTime ? `— ${endTime}` : null} &#183;&#32;</EventBadgeTime>;
    }
  }, [event.allDay, event.recursStartTime, event.endDate]);

  return (
    <>
      <StyledGrid container>
        <Grid item xs={9}>
          <EventBadgeWrapper
            color={event.color}
            allDay={event.allDay}
            onClick={() => setEventBadgeModalState(EEventBadgeModalType.EventDetails)}
          >
            <EventBadgeTitleWrapper>
              <Title>
                { event.title }
              </Title>
            </EventBadgeTitleWrapper>
          </EventBadgeWrapper>

          <EventInfo>
            {eventTime}
          </EventInfo>
        </Grid>

        <Grid item>
          <Actions>
            <Create fontSize="small" onClick={() => setEventBadgeModalState(isRecurringEvent ? EEventBadgeModalType.ConfirmUpdate : EEventBadgeModalType.UpdateEvent)} />
            <Delete fontSize="small" onClick={() => setEventBadgeModalState(EEventBadgeModalType.Delete)} />
          </Actions>
        </Grid>

      </StyledGrid>

      {renderModal()}
    </>
  );
};
