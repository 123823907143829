import { IconButton, MenuItem, Menu as MuiMenu }                                           from '@material-ui/core';
import MoreHorizIcon
  from '@material-ui/icons/MoreHoriz';
import React, { ForwardRefRenderFunction, forwardRef, memo, useContext, useRef, useState } from 'react';
import { MenuAnchor }                                                                      from '../../../Auth/shared/styles';
import { AlertDialog }                                                                     from '../../../shared/components/modals/AlertDialog';
import { isAdminRoles }                                                                    from '../../../shared/functions';
import { fullName }                                                                        from '../../../shared/utils/commonFunctions';
import AuthorizationContext
  from '../../../shared/utils/withAuthorization/authorizationContext';
import { IChat, useDeleteChatRoom }                                                        from '../queries';
import { CreateChatRoomModal }                                                             from './CreateChatRoom/CreateChatRoomModal';

interface IChatMenuItemsProps {
  familyId?: string;
  chat?: IChat;
}

// eslint-disable-next-line react/display-name
const ChatMenuItemsBase: ForwardRefRenderFunction<HTMLDivElement, IChatMenuItemsProps> = ({
  familyId,
  chat,
}, _ref) => {
  const chatUser = chat?.users?.[0];
  const currentUser = useContext(AuthorizationContext);
  const { mutate: deleteChat, isLoading: deletingChatRoom } = useDeleteChatRoom(familyId, currentUser?.data?.id);
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
  const [ updateChatModalOpen, setUpdateChatModalOpen ] = useState(false);
  const isCreator = currentUser?.data?.id === chat?.creator?.id;
  const isAdminRole = isAdminRoles(currentUser?.data?.accountType);
  const recipientFullName = fullName(chatUser?.firstName, chatUser?.lastName);
  const [ anchorEl, setAnchorEl ] = useState(null);
  const menuAnchorRef = useRef(null);

  const handleMenuClick = () => {
    setAnchorEl(menuAnchorRef.current);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onDeleteChat = () => {
    deleteChat(chat?.id, {
      onSuccess: () => {
        setDeleteModalOpen(false);
      },
    });
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <MenuAnchor
          ref={menuAnchorRef}
          color="#fff"
          top="70px"
          right="100px"
        />
        <MoreHorizIcon />
      </IconButton>

      <MuiMenu
        keepMounted
        id="chat-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => setUpdateChatModalOpen(true)}>Edit</MenuItem>

        <MenuItem hidden={!(isCreator || isAdminRole)} onClick={() => setDeleteModalOpen(true)}>Delete</MenuItem>

        <AlertDialog
          dialogWidth="350px"
          open={deleteModalOpen}
          title="Delete chat"
          loading={deletingChatRoom}
          content={`Are you sure you want to remove chat with ${ chat?.name || recipientFullName }?`}
          submitButtonDisabled={deletingChatRoom}
          handleSubmit={() => onDeleteChat()}
          handleCancel={() => setDeleteModalOpen(false)}
        />

        { updateChatModalOpen && (
          <CreateChatRoomModal
            chat={chat}
            familyId={familyId}
            open={updateChatModalOpen}
            onSuccess={() => setUpdateChatModalOpen(false)}
            onClose={() => setUpdateChatModalOpen(false)}
          />
        ) }
      </MuiMenu>
    </>
  );
};

export const ChatMenuItems = memo(forwardRef(ChatMenuItemsBase));
