import { FC, useEffect, useMemo, useState } from 'react';
import moment                      from 'moment';
import styled, { css }             from 'styled-components';
import { EventDetailsModal }       from '../../../shared/components/modals/UploadEvent/EventDetailsModal';
import { IEvent }                  from '../queries';

export const EventBadgeTime = styled.div`
  display: none;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    display: flex;
  }
`;

export const EventBadgeTitleWrapper = styled.div`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.1;
`;

export const EventBadgeWrapper = styled.div<{
  color: string;
  allDay: boolean;
  hasPrevDayEvent?: boolean;
  hasNextDayEvent?: boolean;
}>`
  gap: 4px;
  min-height: 22px;
  display: flex;
  border-top-left-radius: ${ ({ hasPrevDayEvent }) => hasPrevDayEvent ? 0 : '7px' };
  border-bottom-left-radius: ${ ({ hasPrevDayEvent }) => hasPrevDayEvent ? 0 : '7px' };
  border-top-right-radius: ${ ({ hasNextDayEvent }) => hasNextDayEvent ? 0 : '7px' };
  border-bottom-right-radius: ${ ({ hasNextDayEvent }) => hasNextDayEvent ? 0 : '7px' };
  margin: ${ ({ allDay, hasPrevDayEvent, hasNextDayEvent }) => allDay && `0 ${ hasNextDayEvent ? 0 : '2px' } 0 ${ hasPrevDayEvent ? 0 : '2px' }` };
  width: ${ ({ allDay, hasPrevDayEvent, hasNextDayEvent }) => allDay && (hasPrevDayEvent && hasNextDayEvent ? 'calc(100% + 1px)' : !hasPrevDayEvent && !hasNextDayEvent ? 'calc(100% - 4px)' : 'calc(100% - 1px)') };
  align-items: center;
  padding: 4px 8px;
  white-space: nowrap;
  color: #000;
  cursor: pointer;
  position: relative;
  z-index: ${ ({ hasPrevDayEvent, hasNextDayEvent }) => !hasPrevDayEvent && hasNextDayEvent ? 3 : 2 };

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    ${ EventBadgeTitleWrapper } {
      overflow: ${ ({ allDay, hasPrevDayEvent, hasNextDayEvent }) => allDay && (!hasPrevDayEvent && hasNextDayEvent ? 'visible' : 'hidden') };
    }
  }

  ${ ({ allDay, color, hasPrevDayEvent }) => allDay ? css`
    color: ${ hasPrevDayEvent ? '#ffffff00' : '#fff' };
    background-color: ${ color || '#547cd3' };

    ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
      padding: 4px 8px;
    }
  ` : `
    margin: 0 2px;

    &:before {
      content: '';
      width: 12px;
      min-width: 12px;
      height: 12px;
      display: block;
      border-radius: 50%;
      background-color: ${ color || '#547cd3' };
    }
  ` };

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    gap: 8px;
  }
`;

const Title = styled.span<{ maxWidth: number }>`
  ${ ({ maxWidth }) => maxWidth && { 'max-width': `${ maxWidth }px` } };
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
`;

interface IEventBadgeProps {
  single?: boolean;
  day: string;
  event: IEvent;
  familyId?: string;
  allowEdit?: boolean;
  eventDuration?: number;

  handleClose?(): void;
}

export const EventBadge: FC<IEventBadgeProps> = ({
  day,
  event,
  single,
  familyId,
  allowEdit,
  handleClose,
  eventDuration,
}) => {
  const [ eventDetailsModalOpen, setUploadEventModalOpen ] = useState(false);
  const [ titleMaxWidth, setTitleMaxWidth ] = useState(0);

  const onClick = () => {
    if (allowEdit) {
      setUploadEventModalOpen(true);
    }
  };

  const getTitleMaxWidth = () => {
    const maxWidth = (document.getElementsByClassName('event-date-square')[0].getBoundingClientRect().width - 5) * (eventDuration || 1);
    setTitleMaxWidth(maxWidth);
  };

  useEffect(() => {
    eventDuration && window.addEventListener('resize', getTitleMaxWidth);

    return () => window.removeEventListener('resize', getTitleMaxWidth);
  }, []);

  useEffect(() => {
    eventDuration && getTitleMaxWidth();
  }, [ event ]);
  const isHidden = !event?.eventExceptionDays?.includes(day);

  const eventTime = useMemo(() => {
    if (event.allDay) {
      return null;
    }

    if (event.recursStartTime) {
      const startTime = moment.utc(event.recursStartTime).format('h:mm A');
      const endTime = event.endDate ? moment.utc(event.endDate).format('h:mm A') : '';

      return <EventBadgeTime>{startTime} {endTime ? `— ${endTime}` : null} &#183;&#32;</EventBadgeTime>;
    }
  }, [event.allDay, event.recursStartTime, event.endDate]);

  return(
    <>
      {(isHidden) ? (
        <>
          <EventBadgeWrapper
            color={event.color}
            allDay={event.allDay}
            hasPrevDayEvent={!single && event?._prevDayEventIndex !== -1}
            hasNextDayEvent={!single && event?._nextDayEventIndex !== -1}
            onClick={onClick}
          >
            { eventTime }
            <EventBadgeTitleWrapper>
              <Title maxWidth={titleMaxWidth}>
                { event.title }
              </Title>
            </EventBadgeTitleWrapper>
          </EventBadgeWrapper>

          { eventDetailsModalOpen && (
            <EventDetailsModal
              event={event}
              familyId={familyId}
              open={eventDetailsModalOpen}
              onClose={() => {
                setUploadEventModalOpen(false);
                handleClose?.();
              }}
            />
          ) }
        </>
      ) : (
        <span />
      )}
    </>
  );
};
