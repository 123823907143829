import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import React, { FC, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as IconDate } from '../../../../images/icons/icon-date.svg';
import { ReactComponent as IconTime } from '../../../../images/icons/icon-time.svg';
import { Layout } from '../../../Layout/Layout';
import { GridDivider } from '../../../shared/components/layout/GridDivider';
import { AlertDialog } from '../../../shared/components/modals/AlertDialog';
import { useChangeTaskStatus } from '../../../shared/components/modals/UploadTask/queries';
import { NavigateBack } from '../../../shared/components/NavigateBack';
import { useFetchSelectedFamily } from '../../../shared/components/SelectFamily/queries';
import { UploadedFiles } from '../../../shared/components/UploadedFiles';
import { EAppRoutes, ETaskStatus } from '../../../shared/constants/constants';
import { filterMembersById, shouldDisplayActionsMenu } from '../../../shared/functions';
import { useFetchFamilyMembers } from '../../../shared/queries/family';
import { FlexEndGrid, FlexVerticalCenterGrid } from '../../../shared/styledComponents';
import { formatDate, formatTime } from '../../../shared/utils/commonFunctions';
import AuthorizationContext
  from '../../../shared/utils/withAuthorization/authorizationContext';
import { ActivityRecordPreview } from '../../Activity/ActivityRecordPreview';
import { FamilyMembersList } from '../../Dashboard/components/FamilyMembers/FamilyMembersList';
import { LoadingOverlay } from '../../../shared/components/LoadingOverlay';
import { CategoryPills } from '../shared/CategoryPills';
import {
  DetailsPageCreatedDate,
  DetailsPageFooter,
  DetailsPageSpacedLabel,
  EntityDetailsHeader,
  SummaryPageWrapper,
} from '../shared/styles';
import { TaskImageDownload } from './components/TaskImageDownload';
import { TaskMenuItems } from './components/TaskMenuItems';
import { TaskStatus } from './components/TaskStatus';
import { useFetchTaskDetails } from './queries';

const TaskCheckboxHeader = styled.div`
  font-size: 14px;
  padding: 0 20px 15px;
  margin: -15px -32px 15px;
  border-bottom: 1px solid #e9e9e9;
`;

export const TaskStatusWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const TaskDescription = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 23px;
`;

export const DueDateWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

export const DueDate = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  color: #272932;

  &:nth-child(2) svg {
    width: 14px;
    height: auto;
  }
`;

export const DueDateDelimiter = styled.div`
  margin: '0 10px;'
`;

export const TaskDetails: FC = () => {
  const { taskId } = useParams<{ taskId?: string }>();
  const { data: family } = useFetchSelectedFamily();
  const { data: familyMembers } = useFetchFamilyMembers(family?.id, { enabled: !!family?.id });
  const { data: task, isFetching: loadingTask } = useFetchTaskDetails(taskId);
  const { mutate: changeTaskStatus, isLoading: changingTaskStatus } = useChangeTaskStatus(taskId);
  const currentUser = useContext(AuthorizationContext);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const assignedMembers = filterMembersById(familyMembers, task?.assigned);
  const taggedMembers = filterMembersById(familyMembers, task?.tagged);

  const menuItems = (
    <TaskMenuItems
      familyId={family?.id}
      task={task}
    />
  );

  const onMarkComplete = (checked: boolean) => {
    if (!changingTaskStatus) {
      changeTaskStatus(checked ? ETaskStatus.Complete : ETaskStatus.Open);
    }
  };

  const Page = (
    <>
      <NavigateBack
        title="My Family Core"
        to={EAppRoutes.TasksSummary}
      />

      {task && (
        <SummaryPageWrapper>
          <TaskCheckboxHeader>
            <FormControlLabel
              label={'Mark Complete'}
              disabled={changingTaskStatus}
              control={
                <Checkbox
                  value={task?.status}
                  checked={task?.status === ETaskStatus.Complete}
                  onChange={(event) => onMarkComplete(event.target.checked)}
                />
              }
            />
          </TaskCheckboxHeader>

          <EntityDetailsHeader>
            <ActivityRecordPreview
              showMenu={shouldDisplayActionsMenu(task, currentUser)}
              menuItems={menuItems}
              description={task?.title}
            />
          </EntityDetailsHeader>

          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <TaskStatusWrapper>
                <TaskStatus status={task?.status} mb="0" />
                <CategoryPills category={task?.category} />
              </TaskStatusWrapper>
            </Grid>

            <GridDivider margin="5px 0 -5px" />

            <FlexVerticalCenterGrid
              item
              xs={12}
            >
              <DetailsPageSpacedLabel>Assigned</DetailsPageSpacedLabel>

              <FamilyMembersList
                shifted
                hideAllSelection
                data={assignedMembers}
              />
            </FlexVerticalCenterGrid>

            <GridDivider margin="0" />

            <FlexVerticalCenterGrid
              item
              xs={12}
            >
              <DetailsPageSpacedLabel>Due Date</DetailsPageSpacedLabel>

              <DueDateWrapper>
                <DueDate>
                  <IconDate />

                  {formatDate(task?.recursStartDate)}
                  {task?.recursEndDate ? (
                    <>
                      <DueDateDelimiter>-</DueDateDelimiter>
                      {formatDate(task?.recursEndDate)}
                    </>
                  ) : ''}
                </DueDate>

                <DueDate>
                  <IconTime />

                  {formatTime(task?.recursStartTime)}
                </DueDate>
              </DueDateWrapper>
            </FlexVerticalCenterGrid>

            <GridDivider margin="0" />
          </Grid>

          <DetailsPageFooter>
            {task?.location && (
              <>
                <DetailsPageSpacedLabel mb="10px">Location</DetailsPageSpacedLabel>

                <TaskDescription>
                  {task?.location}
                </TaskDescription>

                <GridDivider margin="0 0 24px" />
              </>
            )}

            {task?.description && (
              <>
                <DetailsPageSpacedLabel mb="10px">Description</DetailsPageSpacedLabel>

                <TaskDescription>
                  {task?.description}
                </TaskDescription>

                <GridDivider margin="0 0 24px" />
              </>
            )}

            {(task?.attachments && task.attachments.length > 0) && (
              <>
                <FlexVerticalCenterGrid
                  item
                  xs={12}
                >
                  <UploadedFiles attachments={task?.attachments} entityName="tasks" />
                </FlexVerticalCenterGrid>
                <GridDivider margin="0 0 24px" />
              </>
            )}

            {task?.image && (
              <TaskImageDownload image={task?.image} />
            )}

            <Grid
              container
              spacing={3}
            >
              <FlexVerticalCenterGrid
                item
                xs={12}
                sm={6}
              >
                <DetailsPageSpacedLabel>Created by</DetailsPageSpacedLabel>

                <FamilyMembersList
                  hideAllSelection
                  data={task?.creator && [task?.creator]}
                />

                <DetailsPageCreatedDate>
                  {moment(task?.creationTime).format('MMM D, YYYY')}
                </DetailsPageCreatedDate>
              </FlexVerticalCenterGrid>

              {!!taggedMembers?.length && (
                <FlexEndGrid
                  item
                  xs={12}
                  sm={6}
                >
                  <DetailsPageSpacedLabel>Tagged</DetailsPageSpacedLabel>

                  <FamilyMembersList
                    shifted
                    hideAllSelection
                    data={taggedMembers}
                  />
                </FlexEndGrid>
              )}
            </Grid>
          </DetailsPageFooter>
        </SummaryPageWrapper>
      )}

      <AlertDialog
        dialogWidth="350px"
        open={deleteModalOpen}
        title="Are you sure you want to delete this task?"
        content={`Task "${task?.title}"`}
        loading={changingTaskStatus || loadingTask}
        handleSubmit={() => setDeleteModalOpen(false)}
        handleCancel={() => setDeleteModalOpen(false)}
      />
    </>
  );

  return useMemo(() =>
    (<Layout loading={loadingTask}>
      {Page}
    </Layout>), [currentUser, task, loadingTask, deleteModalOpen, window.location.search, changingTaskStatus]
  );
};
