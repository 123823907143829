import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
}                                                              from 'react';
import styled                                                  from 'styled-components';
import { Box, Grid }                                           from '@material-ui/core';
import { Form, Formik, FormikHelpers, FormikProps }            from 'formik';
import { Layout }                                              from '../../Layout/Layout';
import { useFetchSelectedFamily }                              from '../../shared/components/SelectFamily/queries';
import { SwitchField }                                         from '../../shared/formFields/SwitchField';
import { isAdminRoles }                                        from '../../shared/functions';
import { useFetchFamilyUser }                                  from '../../shared/queries/family';
import {
  FlexVerticalCenterGrid,
  ResponsivePageTitle,
  StyledDivider,
}                                                               from '../../shared/styledComponents';
import AuthorizationContext                                     from '../../shared/utils/withAuthorization/authorizationContext';
import { SummaryPageWrapper }                                   from '../MyFamilyCore/shared/styles';
import { FlexEndGrid }                                          from '../Settings/components/ManageFamilyAccount/ManageAccountForm/ManageAccountForm';
import { FormFooterActions }                                    from '../Settings/shared/FormFooterActions';
import {
  EPlatform,
  PlatformSelector,
  SectionHeader,
  TContactPlatform,
}                                                                from './components/PlatformSelector';
import {
  Subsection,
  ToggleSection,
}                                                                from './components/ToggleSection';
import {
  useFetchNotificationPreferences,
  useUpdateNotificationPreferences,
}                                                                from './queries';
import { notificationPreferencesValidationSchema }               from './validation';
import { EFamilyAccountType }                                    from '../../shared/utils/withAuthorization/withAuthorization';
import { LoadingOverlay } from '../../shared/components/LoadingOverlay';

const NotificationsPage = styled.div`
  margin: 0 -17px;
  width: calc(100% + 34px);
`;

export const OptionDescription = styled.div`
  line-height: 1.57;
  margin-top: 12px;
  flex: 1;
`;

export const SpacedDivider = styled(StyledDivider)`
  margin: 35px 0 35px;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    margin: 50px 0 55px;
  }
`;

const FormFooter = styled.div`
  margin: -22px;
  border-radius: 0 0 8px 8px;
  overflow: hidden;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    margin: -32px;
  }
`;

const OptInOutText = styled.div`
  line-height: 1.5;
  min-height: 70px;
`;

interface IRecord {
  name: string;
  title: string;
  onChange?: (value: boolean) => void;
}

interface IReminderRecord extends IRecord {
  subtitle?: string;
}

export interface INotificationPreferencesForm {
  contactGeneralMarketing: TContactPlatform[];
  contactDailySummaries: TContactPlatform[];
  contactNewMembersJoin: TContactPlatform[];
  contactReminders?: TContactPlatform[];
  contactRemindersMyTasks?: TContactPlatform[];
  contactRemindersAllTasks?: TContactPlatform[];
  contactRemindersMyEvents?: TContactPlatform[];
  contactRemindersAllEvents?: TContactPlatform[];
  contactRemindersBirthday?: TContactPlatform[];
  contactNewItemsAdded: TContactPlatform[];
  contactUpdatesAndDeletions: TContactPlatform[];
  contactDocumentsViewAlert: TContactPlatform[];
  contactIncomingChatMessage: TContactPlatform[];
  contactIncomingCheckInLocation: TContactPlatform[];
  contactAboutMeUpdates: TContactPlatform[];

  news: boolean;
};

export const NotificationPreferences = () => {
  const { data: family } = useFetchSelectedFamily();
  const { data: user } = useFetchFamilyUser(family?.id);
  const { data } = useFetchNotificationPreferences(user?.id);
  const { mutate: updatePreferences, isLoading: updatingPreferences } = useUpdateNotificationPreferences(user?.id);

  const account = useContext(AuthorizationContext);
  const isAdminRole = isAdminRoles(account?.data?.accountType);
  const isSuperAdminRole = account?.data?.accountType === EFamilyAccountType.SuperAdmin;

  const formikRef = useRef<FormikProps<INotificationPreferencesForm>>(null);

  const defaultValue = {
    contactGeneralMarketing        : [],
    contactDailySummaries          : [],
    contactNewMembersJoin          : [],
    contactRemindersMyTasks        : [],
    contactRemindersAllTasks       : [],
    contactRemindersMyEvents       : [],
    contactRemindersAllEvents      : [],
    contactRemindersBirthday       : [],
    contactNewItemsAdded           : [],
    contactUpdatesAndDeletions     : [],
    contactDocumentsViewAlert      : [],
    contactIncomingChatMessage     : [],
    contactIncomingCheckInLocation : [],
    contactAboutMeUpdates          : [],

    news: false,
  };

  const [ initialState, setInitialState ] = useState<INotificationPreferencesForm>(defaultValue);

  useEffect(() => {
    if (data) {
      setInitialState(data);
    }
  }, [ data ]);

  const handleFieldChange = useCallback(
    (value: string): void => {

      if (formikRef?.current) {
        const { setFieldValue } = formikRef?.current;

        (value === "false") ?
          setFieldValue('contactIncomingCheckInLocation', [ EPlatform.email, EPlatform.inApp , EPlatform.mobile ])
          : setFieldValue('contactIncomingCheckInLocation', [ EPlatform.inApp , EPlatform.mobile ]);
      }
    },
    []);

  const handleCheckboxChange = useCallback(
    (value: TContactPlatform): void => {
      if (formikRef?.current) {
        const { setFieldValue, values } = formikRef?.current;

        if (value === EPlatform.all) {
          (values.contactIncomingCheckInLocation.length === 3) ?
            setFieldValue('familyNotifyMeForCheckin', false)
            : setFieldValue('familyNotifyMeForCheckin', true);
        }

        if (value !== EPlatform.all) {

          const emailOn = values.contactIncomingCheckInLocation.includes(value);

          emailOn ?
            setFieldValue('familyNotifyMeForCheckin', false)
            : setFieldValue('familyNotifyMeForCheckin', true);
        }
      }
    },
    []);

  const handleSubmit = (values: INotificationPreferencesForm, formikActions: FormikHelpers<INotificationPreferencesForm>) => {
    updatePreferences({
      ...values,
    });
    formikActions.setSubmitting(false);
  };

  const contactPreferences = [
    {
      title   : 'New Members Join',
      name    : 'contactNewMembersJoin',
      allowed : [ EPlatform.email, /*EPlatform.mobile,*/ EPlatform.mobile ],
      show    : true,
    }, {
      title    : 'Reminders',
      name     : 'contactReminders',
      allowed  : [],
      show     : true,
      subItems : [
        {
          title   : 'My Tasks',
          name    : 'contactRemindersMyTasks',
          allowed : [ EPlatform.email, EPlatform.inApp, EPlatform.mobile ],
          show    : true,
        }, {
          title   : 'All Tasks',
          name    : 'contactRemindersAllTasks',
          allowed : [ EPlatform.email, EPlatform.inApp, EPlatform.mobile ],
          show    : (isAdminRole || isSuperAdminRole),
        }, {
          title   : 'My Events',
          name    : 'contactRemindersMyEvents',
          allowed : [ EPlatform.email, EPlatform.inApp, EPlatform.mobile ],
          show    : true,
        }, {
          title   : 'All Events',
          name    : 'contactRemindersAllEvents',
          allowed : [ EPlatform.email, EPlatform.inApp, EPlatform.mobile ],
          show    : (isAdminRole || isSuperAdminRole),
        }, {
          title   : 'Birthday',
          name    : 'contactRemindersBirthday',
          allowed : [ EPlatform.email, EPlatform.inApp, EPlatform.mobile ],
          show    : true,
        }, {
          title   : 'Birthday',
          name    : 'contactRemindersBirthday',
          allowed : [ EPlatform.email, EPlatform.inApp, EPlatform.mobile ],
          show    : false,
        },
      ],
    },{
      title    : 'New Items',
      subtitle : 'Docs, Contacts, Events & Tasks',
      name     : 'contactNewItemsAdded',
      allowed  : [ EPlatform.email, EPlatform.inApp, EPlatform.mobile ],
      show     : true,
    }, {
      title    : 'Modified Items',
      subtitle : 'Docs, Contacts, Events & Tasks',
      name     : 'contactUpdatesAndDeletions',
      allowed  : [ EPlatform.email, EPlatform.inApp, EPlatform.mobile ],
      show     : true,
    }, {
      title   : 'Document Viewed Alert',
      name    : 'contactDocumentsViewAlert',
      allowed : [ EPlatform.email, EPlatform.inApp, EPlatform.mobile ],
      show    : true,
    },{
      title   : 'Chat Messages Incoming',
      name    : 'contactIncomingChatMessage',
      allowed : [ EPlatform.email, EPlatform.inApp, EPlatform.mobile ],
      show    : true,
    }, {
      title    : 'Check In (Geo Location)',
      name     : 'contactIncomingCheckInLocation',
      onChange : handleCheckboxChange,
      allowed  : [ EPlatform.email, EPlatform.inApp, EPlatform.mobile ],
      show     : true,
    }, {
      title   : '"About Me" Modified',
      name    : 'contactAboutMeUpdates',
      allowed : [ EPlatform.email /*EPlatform.mobile,*/ /*EPlatform.inApp,*/ ],
      show    : true,
    }, {
      title    : 'Daily Email Summaries',
      subtitle : 'Events & Tasks',
      name     : 'contactDailySummaries',
      allowed  : [ EPlatform.email /*EPlatform.mobile,*/ /*EPlatform.inApp,*/ ],
      show     : true,
    },
  ];

  const Page = (
    <NotificationsPage>
      <ResponsivePageTitle
        margin="3px 25px 30px"
        marginSm="3px 10px 30px"
        marginMd="0 0 40px"
      >
        Notification Settings
      </ResponsivePageTitle>

      <SummaryPageWrapper>
        <Formik
          enableReinitialize
          innerRef={formikRef}
          initialValues={{
            ...initialState,
          }}
          validationSchema={notificationPreferencesValidationSchema}
          onSubmit={(value, props) => handleSubmit(value, props)}
        >
          { ({ values, dirty, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>

              <Subsection>
                <Grid container spacing={3}>
                  <Grid item sm={12} md={10} lg={7}>
                    {contactPreferences
                      .filter(preference => preference.show)
                      .map((item, index) => (
                        <div key={index}>
                          <PlatformSelector
                            title={item.title}
                            subtitle={item.subtitle}
                            fieldName={item.name}
                            displayLabels={!index}
                            allowed={item.allowed}
                            last={index === contactPreferences.length - 1}
                            onChange={item?.onChange}
                          />
                          {item.subItems && item.subItems
                            .filter(subItem => subItem.show)
                            .map((subItem, subIndex) => (
                              <div key={`${index}-${subIndex}`} style={{ paddingLeft: '15px' }}>
                                <PlatformSelector
                                  isSubItem = {!!subItem}
                                  title={subItem.title}
                                  fieldName={subItem.name}
                                  displayLabels={false}
                                  allowed={subItem.allowed}
                                  last={subIndex === item.subItems!.length - 1}
                                />
                              </div>
                            ))}
                        </div>
                      ))}
                  </Grid>
                </Grid>
              </Subsection>

              <OptionDescription>
                {/*Description if needed*/}
              </OptionDescription>

              <FormFooter>
                <FormFooterActions
                  dirty={dirty}
                  isLoading={isSubmitting}
                  formikRef={formikRef}
                  isDisabled={updatingPreferences}
                />
              </FormFooter>
            </Form>
          ) }
        </Formik>
      </SummaryPageWrapper>
      <LoadingOverlay loading={updatingPreferences} />
    </NotificationsPage>
  );

  return (
    <Layout>
      { Page }
    </Layout>
  );
};
