import { FC, useRef, useState }    from 'react';
import { DialogProps }             from '@material-ui/core';
import { Clear }                   from '@material-ui/icons';
import { Formik, FormikProps }     from 'formik';
import moment, { Moment }                      from 'moment';
import { IFileShape }              from '../../../../Auth/shared/interfaces';
import { IEvent }                  from '../../../../modules/Schedule/queries';
import { EDocumentAccess, colors } from '../../../constants/constants';
import { attachmentToFileShape }   from '../../../functions';
import { CloseModalIcon }          from '../../../styledComponents';
import { ERecurrence }             from '../shared';
import {
  StyledUploadDialog,
  UploadDialogContent,
  UploadDialogTitle,
}                                   from '../styles';
import {
  IUploadEvent,
  useUpdateEvent,
  useUploadEvent,
}                                   from './queries';
import { UploadEventModalForm }     from './UploadEventModalForm';
import { addEventFormSchema }       from './validation';
import styled                       from 'styled-components';
import { LimitedModal }             from '../UploadTask/LimitedModal';

interface IAlertDialogProps extends DialogProps {
  familyId?: string;
  event?   : IEvent;
  schedule?: Array<IEvent>;
  singularEventDate?: Moment | null;
  onClose  : () => void;
}

const DialogSubtitle = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

export const UploadEventModal: FC<IAlertDialogProps> = ({
  event,
  schedule,
  familyId,
  singularEventDate,
  onClose,
  ...props
}) => {
  const initFiles = event?.attachments ? attachmentToFileShape(event.attachments) : [];
  const formikRef = useRef<FormikProps<IUploadEvent>>(null);

  const [ files, setFile ] = useState<IFileShape[]>(initFiles as IFileShape[]);
  const [isLimitCategory, setIsLimitCategory ] = useState(false);
  const { mutate: uploadEvent, isLoading: uploadingEvent } = useUploadEvent();
  const { mutate: updateEvent, isLoading: updatingEvent } = useUpdateEvent();

  const initialValues: IUploadEvent = {
    id                 : event?.id,
    title              : event?.title || '',
    assigned           : event?.assigned || [],
    assignedPermission : event?.assignedPermission || EDocumentAccess.ViewAndEdit,
    description        : event?.description || '',
    tagged             : event?.tagged || [],
    familyId           : familyId as string,
    categoryId         : event?.category?.id || '',
    recurs             : event?.recurs || ERecurrence.NoRecurrence,
    recursInterval     : event?.recursInterval,
    recursDay          : event?.recursDay || null,
    recursOrdinal      : event?.recursOrdinal,
    recursWeekDays     : event?.recursWeekDays,
    recursCount        : event?.recursCount || null,
    recursStartDate    : event?.recursStartDate || '',
    recursEndDate      : event?.recursEndDate || '',
    recursStartTime    : event?.recursStartTime || '',
    endDate            : event?.endDate ?? '',
    alertInterval      : event?.alertInterval || 0,
    allDay             : event?.allDay || false,
    alarmRecurs        : event?.alarmRecurs || '',
    location           : event?.location || '',
    color              : event?.color || colors[0],
    eventExceptionDays : event?.eventExceptionDays,
  };

  const handleClose = () => {
    setFile([]);
    onClose();
  };

  const upload = (values: IUploadEvent) => {
    const attachmentsId = files.filter(file => !!file.id).map(file => file.id) as string[];

    values = {
      ...values,
      offset: moment().utcOffset(),
    };

    if (event?.id) {
      updateEvent({
        data: {
          ...values,
          ...(singularEventDate && { eventUpdatedDays: [singularEventDate.format()] }),
          attachmentsId,
        },
        files: files.filter(fileShape => !fileShape.id),
      }, {
        onSuccess : handleClose,
        onError   : (error) => {
          if (error.response.data.ErrorDetails.Key === 'MaxEventNumber') {
            setIsLimitCategory(true);
          }
        },
      });
    } else {
      uploadEvent({
        data: values,
        files,
      }, {
        onSuccess : handleClose,
        onError   : (error) => {
          if (error.response.data.ErrorDetails.Key === 'MaxEventNumber') {
            setIsLimitCategory(true);
          }
        },
      });
    }
  };

  return (
    <StyledUploadDialog
      onClose={handleClose}
      {...props}
    >
      <CloseModalIcon
        aria-label="close modal window"
        onClick={handleClose}
      >
        <Clear />
      </CloseModalIcon>

      <UploadDialogTitle>
        { event?.id ? 'Edit' : 'Add' } Event

        {event?.id && event?.recurs !== ERecurrence.NoRecurrence ? (
          <DialogSubtitle>
            {singularEventDate ? "You're editing This Event of a series" : "You're editing All Events of a series"}
          </DialogSubtitle>
        ) : null}
      </UploadDialogTitle>

      <LimitedModal
        openFreeInfo={isLimitCategory}
        entityName="MaxEventNumber"
        setOpenFreeInfo={setIsLimitCategory}
      />

      <UploadDialogContent>
        <Formik
          enableReinitialize
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={addEventFormSchema}
          onSubmit={upload}
        >
          <UploadEventModalForm
            schedule={schedule}
            files={files}
            familyId={familyId}
            event={event}
            formikRef={formikRef}
            loading={uploadingEvent || updatingEvent}
            disableRecursField={!!singularEventDate}
            onFileAdd={setFile}
          />
        </Formik>
      </UploadDialogContent>
    </StyledUploadDialog>
  );
};

UploadEventModal.defaultProps = {
  disableBackdropClick: true,
};
