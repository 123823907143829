import { MenuItem }                                                                             from '@material-ui/core';
import React, { ForwardRefRenderFunction, forwardRef, memo, useCallback, useContext, useState } from 'react';
import { useHistory, useParams }                                                                from 'react-router-dom';
import { ProvidePinModal }                                                                      from '../../../../shared/components/modals/ProvidePinModal';
import {
  IDocument,
  useDeleteDocument,
}                                                                                               from '../../../../shared/components/modals/UploadDocument/queries';
import { UploadDocumentModal }                                                                  from '../../../../shared/components/modals/UploadDocument/UploadDocumentModal';
import { EAppRoutes }                                                                           from '../../../../shared/constants/constants';
import { isAdminRoles }                                                                         from '../../../../shared/functions';
import AuthorizationContext
  from '../../../../shared/utils/withAuthorization/authorizationContext';

interface IDocumentMenuItemsProps {
  familyId?: string;
  familyUserId?: string;
  document?: IDocument;
}

// eslint-disable-next-line react/display-name
const DocumentMenuItemsBase: ForwardRefRenderFunction<HTMLDivElement, IDocumentMenuItemsProps> = ({
  familyId,
  familyUserId,
  document,
}, _ref) => {
  const currentUser = useContext(AuthorizationContext);
  const { mutate: deleteDocument, isLoading: isDeleteDocument } = useDeleteDocument();
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
  const [ updateDocumentModalOpen, setUpdateDocumentModalOpen ] = useState(false);
  const isCreator = currentUser?.data?.id === document?.creator?.id;
  const isAdminRole = isAdminRoles(currentUser?.data?.accountType);
  const history = useHistory();
  const { documentId } = useParams<{ documentId: string }>();
  console.log('isDeleteDocument', isDeleteDocument);

  const handleDocumentDeletion = useCallback((securityCode: string, options: any) => {
    deleteDocument({
      id: document?.id,
      securityCode,
      familyId,
    }, options);
    if (documentId) {
      history.push(EAppRoutes.Dashboard);
    }
  }, []);

  return (
    <>
      <MenuItem onClick={() => setUpdateDocumentModalOpen(true)}>Edit</MenuItem>
      <MenuItem hidden={!(isCreator || isAdminRole)} onClick={() => setDeleteModalOpen(true)}>Delete</MenuItem>

      <ProvidePinModal
        action="delete this document"
        open={deleteModalOpen}
        loading={isDeleteDocument}
        onSuccess={handleDocumentDeletion}
        onClose={() => setDeleteModalOpen(false)}
      />

      { updateDocumentModalOpen && (
        <UploadDocumentModal
          familyId={familyId}
          familyUserId={familyUserId}
          document={document}
          open={updateDocumentModalOpen}
          onClose={() => setUpdateDocumentModalOpen(false)}
        />
      ) }
    </>
  );
};

export const DocumentMenuItems = memo(forwardRef(DocumentMenuItemsBase));
