import * as Yup from 'yup';

export const addEventFormSchema = Yup.object().shape({
  title              : Yup.string().required('Event Title is required'),
  assigned           : Yup.array().min(1, 'Event has to be assigned').of(Yup.string()).required('Event has to be assigned').nullable(),
  assignedPermission : Yup.string().required('Assigned permission is required'),
  description        : Yup.string().nullable(),
  tagged             : Yup.array().of(Yup.string()).nullable(),
  categoryId         : Yup.string().required('Category is required'),
  recursStartDate    : Yup.string().required('Date is required'),
  recursStartTime    : Yup.string(),
  endDate            : Yup.string().test(
    'is-valid-end-date',
    'End date must be later than start date, unless recurrence end date is provided',
    function (value) {
      const { recursStartTime, recursEndDate } = this.parent;

      if (!value) {
        return true;
      }

      const startTime = new Date(recursStartTime).getTime();
      const endTime = new Date(value).getTime();
      const recurrenceEndTime = recursEndDate ? new Date(recursEndDate).getTime() : null;

      if (isNaN(startTime) || isNaN(endTime)) {
        return this.createError({ message: 'Invalid date format' });
      }

      if (recurrenceEndTime !== null) {
        return true;
      }

      return endTime > startTime || this.createError({ message: 'End date must be later than start date' });
    }
  ),
});
