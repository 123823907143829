import { AxiosError, AxiosResponse }  from 'axios';
import moment, { Moment, unitOfTime } from 'moment-timezone';
import React                          from 'react';
import NumberFormat                   from 'react-number-format';
import { InfiniteData }               from 'react-query';
import scrollIntoView                 from 'smooth-scroll-into-view-if-needed';
import { IPaginatedResponse }         from '../../Auth/shared/interfaces';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const sleep = (time: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, time));

export const isIE = (): boolean =>
  /Edge\/|Trident\/|MSIE/.test(window.navigator.userAgent);

export const randomNumber = (floor: number, ceil: number) =>
  Math.ceil(Math.random() * (ceil - floor) + floor);

export const roundCents = (value: number): string | 0 =>
  value && (Math.round(value * 100) / 100).toFixed(2); // Rounding to the nearest cent

export const dateRegex = new RegExp(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/);
export const timeRegex = new RegExp(/^(0[0-9]|1[012]):[0-5][0-9](\s)?(am?|pm?)$/, 'i');
export const getMoment = (dateString: string): Moment => moment.utc(dateString, true);

interface IFormatDateOptions {
  format?: string;
  emptyPlaceholder?: string;
  getMoment?: (dateString: string) => Moment;
}

export const capitalizeFirstLetter = (str = '') => str ? str.charAt(0).toUpperCase() + str.slice(1) : '';

export const fullName = (firstName?: string, lastName?: string) => (firstName && lastName
  && `${ capitalizeFirstLetter(firstName) } ${ capitalizeFirstLetter(lastName) }`) || '';

export const formatDate = (
  dateString?: string | null,
  options?: IFormatDateOptions,
): string => {
  const resultOptions = {
    format           : 'MM/DD/YYYY',
    emptyPlaceholder : '—',
    getMoment,
    ...options,
  };

  if (!dateString) {
    return resultOptions.emptyPlaceholder;
  }

  const dateMoment = resultOptions.getMoment(dateString);
  const result = dateMoment.format(resultOptions.format);

  return result;
};

export const formatDateISO = (dateString?: string, options?: IFormatDateOptions): string =>
  formatDate(dateString, {
    ...options,
    format: DATE_FORMAT,
  });

export const formatTime = (dateString?: string, options?: IFormatDateOptions): string =>
  formatDate(dateString, {
    ...options,
    format: 'h:mm A',
  });

export const formatSeconds = (seconds: number): string => {
  const value = (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/);

  return value ? value[0] : '';
};

export const getTimeBetweenDates = (
  start: string | null,
  end: string | null,
  unitOfTime: unitOfTime.Diff = 'days'
): number | undefined => {
  if (!start || !end) return;

  const startMoment = moment(start);
  const endMoment = moment(end);

  if (!startMoment.isValid() || !endMoment.isValid()) return;

  return endMoment.diff(startMoment, unitOfTime);
};

export const mergeDateAndTime = (dateString?: string, timeString?: string) => {
  if (dateString && timeString) {
    const [ date ] = dateString.split('T');
    const [ _date, time ] = timeString.split('T');
    return `${ date }T${ time }`;
  }

  return dateString;
};

export const datePassed = (date?: string, withTimeZoneOffset = false) => {
  const stillUtc = moment.utc(date).toDate();
  const localDate = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');

  const docDate = withTimeZoneOffset ? moment(localDate) : moment(localDate).utc();
  const currDate = moment();
  const daysPassed = currDate.diff(docDate, 'weeks');

  if (daysPassed > 1) {
    return docDate.format('LLL');
  } else {
    return docDate.calendar(null, {
      sameDay: 'hh:mm A',
    });
  }
};

export const generateInitials = (firstName?: string, lastName?: string) => {
  if (firstName && lastName) {
    return firstName[0] + lastName[0];
  }
  return '';
};

export const generateShortName = (firstName?: string, lastName?: string) => {
  if (firstName && lastName) {
    return `${ firstName } ${ lastName[0].toUpperCase() }.`;
  }
  return '';
};

export const formatBytes = (bytes = 0, decimals = 2) => {
  if (!bytes) {
    return '0 Bytes';
  }

  const kb = 1024;
  const sizes = [ 'Bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb' ];
  const i = Math.floor(Math.log(bytes) / Math.log(kb));

  return `${ parseFloat((bytes / Math.pow(kb, i)).toFixed(decimals)) } ${ sizes[i] }`;
};

export const formatCurrency = (value: number | null) => {
  const val = value !== Infinity ? value !== null ? value : '' : 0;

  return (
    <NumberFormat
      thousandSeparator
      value={val}
      prefix="$"
      displayType={'text'}
      decimalScale={2}
    />
  );
};

export const formatPercentage = (value: number) => (
  <NumberFormat
    thousandSeparator
    value={value * 100}
    suffix="%"
    displayType={'text'}
    decimalScale={2}
  />
);

export const formatPhoneNumber = (phoneNumber?: string): string | undefined => {
  const cleaned = phoneNumber?.replace(/\D+/g, '') || '';

  if (cleaned.length > 10) {
    const countryCode = cleaned.slice(0, cleaned.length - 10);
    const areaCode = cleaned.slice(-10, -7);
    const prefix = cleaned.slice(-7, -4);
    const lineNumber = cleaned.slice(-4);

    return `+${countryCode} (${areaCode}) ${prefix}-${lineNumber}`;
  }

  if (cleaned.length === 10) {
    const areaCode = cleaned.slice(0, 3);
    const prefix = cleaned.slice(3, 6);
    const lineNumber = cleaned.slice(6);

    return `(${areaCode}) ${prefix}-${lineNumber}`;
  }

  return phoneNumber;
};

export const formatSSN = (ssnNumber?: string | null): string => {
  const cleaned = ssnNumber?.replace(/\D+/g, '') || '';

  const ssnRegex = new RegExp(/(\d{3})(\d{2})(\d{4})/);

  const match = ssnRegex.exec(cleaned);

  if (match) {
    return `${ match[1] }-${ match[2] }-${ match[3] }`;
  }

  return ssnNumber || '';
};

export const hexToRGBA = (hex: string, alpha = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${ r }, ${ g }, ${ b }, ${ alpha })`;
};

export const flattenArray = ([ first, ...rest ]: any[]): any[] => {
  if (first === undefined) {
    return [];
  } else if (!Array.isArray(first)) {
    return [ first, ...flattenArray(rest) ];
  } else {
    return [ ...flattenArray(first), ...flattenArray(rest) ];
  }
};

export const scrollToErrorInput = () => {
  setTimeout(() => {
    const errorField = document.getElementsByClassName('Mui-error')?.[0];

    if (errorField) {
      scrollIntoView(errorField, {
        scrollMode : 'if-needed',
        block      : 'center',
        inline     : 'center',
      });
    }
  }, 100);
};

const phoneRegex = new RegExp(/^\+1([2-9][0-9]{2})([2-9][0-9]{2})([0-9]{4})$/);

export const phoneNumberIsValid = (phone: string) =>
  phoneRegex.test(phone)
  && !(phone[3] === '1' && phone[4] === '1')
  && !(phone[6] === '1' && phone[7] === '1');

export const normalizePhoneNumber = (phoneNumberString?: string | null) => {
  const cleaned = `${ phoneNumberString }`.replace(/\D/g, '');
  const match = cleaned.match(/^1?(\d{3})(\d{0,3})(\d{0,4})$/);

  if (match) {
    return [ '+1', match[1], match[2], match[3] ].join('');
  }

  return null;
};

const zipCodeRegex = new RegExp(/^\d{5}-?(\d{4})?$/);

export const zipCodeIsValid = (zipCode: string | null) =>
  !!zipCode && zipCodeRegex.test(zipCode);

export const guidRegex = new RegExp(/^([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}$/);

export const toISOStringWithoutTimeZoneOffset = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${ year }-${ month < 10 ? `0${ month }` : month }-${ day < 10 ? `0${ day }` : day }T00:00:00`;
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  return JSON.parse(window.atob(base64));
};

export const createAndDownloadCSV = (data: string, fileName?: string) => {
  const blob = new Blob([ data ], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('target', '_blank');
  link.download = `${ fileName || 'document' }.csv`;
  link.click();
};

export const createAndDownloadFile = ({ data, headers }: AxiosResponse<any>, definedFileName?: string) => {
  let filename;

  if (definedFileName) {
    filename = definedFileName;
  } else {
    const fileNameRegex = new RegExp(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;"']*)['"]?;?/, 'i');
    const safeFileNameRegex = new RegExp(/[*.,"`'<>#%{}|\\^~[\];:?@=&]/, 'g');

    const contentDisposition = headers['content-disposition'];
    const matched = fileNameRegex.exec(contentDisposition) ?? [];
    const safeFileName = matched[1]?.replace(safeFileNameRegex, '_');

    filename = safeFileName || formatDate(new Date().toISOString(), { format: 'MM-DD-YY_hh-mm_A' });
  }

  const contentType = headers['content-type'];

  const blob = new Blob([ data ], { type: contentType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.download = filename;
  link.target = '_blank';
  link.click();
  URL.revokeObjectURL(url);
};

export const sortComparator = (key: string, order: 'asc' | 'desc' = 'asc') =>
  (a: any, b: any) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    let comparison = 0;
    let varA = a[key] || '';
    let varB = b[key] || '';

    const isStrings = typeof varA === 'string' && typeof varB === 'string';
    const isDates = isStrings && moment(varA).isValid() && moment(varB).isValid();
    const isNumbers = Number.isFinite(+varA) && Number.isFinite(+varB);

    if (isNumbers || isDates) {
      if (isDates) {
        varA = new Date(varA).getTime();
        varB = new Date(varB).getTime();
      }

      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
    } else {
      comparison = varA.localeCompare(varB);
    }

    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };

export const arrayOfType = (data: unknown[] | null | undefined, Type: any) =>
  data?.length ? data.map(item => new Type(item)) : [];

export const arrayFactory = (data: unknown[] | null | undefined, factoryFn: any) =>
  data?.length ? data.map(item => factoryFn(item)) : [];

export const isDateValid = (dateValue?: string | null): boolean => {
  if (!dateValue) {
    return true;
  }

  return !isNaN(new Date(dateValue).valueOf());
};

export const formatBackEndErrors = (error: AxiosError<any>): string => {
  if (!error?.response?.data) {
    return '';
  }

  const { data } = error.response;

  // Check if data is a plain error message string
  if (typeof data === 'string') {
    return data;
  }

  // Check for 'DisplayMessage' prop
  if (data.DisplayMessage) {
    return data.DisplayMessage;
  }

  // Check for 'errors' prop
  if (data.errors) {
    const { errors } = data;

    const errorString = Object.keys(errors).reduce(
      (result: string, key: string) =>
        (errors[key] && Array.isArray(errors[key]))
          ? `${ result }
             ${ key }:
             • ${ errors[key].join('\n• ') }`
          : result,
      ''
    );

    return errorString.trim();
  }

  // Check for other props
  const descriptions: string[] | undefined =
          data.Error
          || data.Description
          || flattenArray(Object.values(data));

  const errorString = (descriptions || []).reduce(
    (result: string, errorMsg: string) =>
      (errorMsg && typeof errorMsg === 'string')
        ? `${ result }${ result.length ? '\n' : '' }${ errorMsg }`
        : result,
    ''
  );

  return errorString.trim();
};

export const isArray = (v: any) => Array.isArray(v);
export const isEmptyArray = (v: any) => isArray(v) && !v.length;
export const isObject = (v: any) => Object.prototype.toString.call(v) === '[object Object]';
export const isEmptyObject = (v: any) => isObject(v) && !Object.keys(v).length;

export const addSpaceBeforeCapitals = (string = '') => string.replace(/([A-Z])/g, ' $1').trim();
export const convertToInfinitePage = (response?: InfiniteData<IPaginatedResponse<any>>) =>
  flattenArray(response?.pages?.map(group => group?.items?.map(item => item)) || []);

export const isCurrentDay = (firstDay: Moment, secondDay: Moment) =>
  firstDay.format('D') === secondDay.format('D')
  && firstDay.format('M') === secondDay.format('M')
  && firstDay.format('YYYY') === secondDay.format('YYYY');

export const formatDateWithZ = (day: Moment) => day.format().substring(0, day?.format().length - 1);
