import styled    from 'styled-components';
import { FC }    from 'react';
import { Link }  from 'react-router-dom';
import { Modal } from '../Modal';

const ModalHeader = styled.p`
  color: #547cd3;
  font-weight: 700;
  font-size: 30px;
  margin: 15px auto;
  text-align: justify;

  ${ ({ theme: { breakpoints } }) => breakpoints.down('md') } {
      font-size: 22px;
    }

    ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
      font-size: 22px;
    }
`;

const ModalText = styled.p`
  color: #656565;
  font-size: 20px;
  margin: 13px 35px;
  text-align: justify;

    ${ ({ theme: { breakpoints } }) => breakpoints.down('md') } {
      font-size: 17px;
    }

    ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
      font-size: 15px;
    }
`;

const ChangeSubscriptionWrapper = styled(Link)`&& {
  display: flex;
  width: 100%;
  font-size: 20px;
  font-family: 'Jost';
  margin:  0 auto 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}`;

export const LimitedModal: FC<any>  = ({
  openFreeInfo,
  setOpenFreeInfo,
  entityName,
}) => {
  const getCorrectText = () => {
    if ((entityName === 'DocumentCategory') || (entityName === 'Documents')) {
      return (
        <ModalText>
          You've reached your limit of 8 documents for this category per your family. Use another category or go to Manage Subscription to upgrade your account to unlock Unlimited Documents.
        </ModalText>
      );
    } else if (entityName === 'CreateCategory') {
      return (
        <ModalText>
          Go to Manage Subscription to upgrade your account to unlock Unlimited Categories for your Documents, Contacts, Tasks, and Events.
        </ModalText>
      );
    } else if ((entityName === 'MaxEventNumber') || (entityName === 'Events')) {
      return (
        <ModalText>
          You've reached your limit of 15 calendar events for the month per your family account. Go to Manage Subscription to upgrade your account to unlock Unlimited Calendar Events.
        </ModalText>
      );
    } else if (entityName === 'GoogleCalendar') {
      return (
        <ModalText>
          This feature is only available for premium accounts. Go to Manage Subscription to upgrade your account.
        </ModalText>
      );
    } else {
      return (
        <ModalText>
          You have added the maximum number of {entityName}. If you want to be able to add more, go to the premium subscription.
        </ModalText>
      );
    }
  };

  return (
    <Modal
      open={openFreeInfo}
      dialogWidth="500px"
      onClose={() => setOpenFreeInfo(false)}
    >
      <ModalHeader>This is a Premium feature</ModalHeader>

      {getCorrectText()}

      <ChangeSubscriptionWrapper to="/settings/manage-account#changeSubscriptionSettings">
        Manage Subscription
      </ChangeSubscriptionWrapper>
    </Modal>
  );
};
