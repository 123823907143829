import { MenuItem }                                                          from '@material-ui/core';
import React, { ForwardRefRenderFunction, forwardRef, useContext, useState } from 'react';
import styled                                                                from 'styled-components';
import { queryClient }                                                       from '../../../../../App';
import { EVENTS }                                                            from '../../../../../modules/MyFamilyCore/events/queries';
import {
  IEvent,
  useDeleteEvent,
}                                                                            from '../../../../../modules/Schedule/queries';
import { isAdminRoles }                                                      from '../../../../functions';
import AuthorizationContext
  from '../../../../utils/withAuthorization/authorizationContext';
import { AlertDialog }                                                       from '../../AlertDialog';
import { UploadEventModal }                                                  from '../UploadEventModal';

const DeleteEventContent = styled.div`
  word-break: break-all;
`;

interface IEventMenuItemsProps {
  familyId?: string;
  event?: IEvent;

  handleClose?(): void;
}

// eslint-disable-next-line react/display-name
const EventMenuItemsBase: ForwardRefRenderFunction<HTMLDivElement, IEventMenuItemsProps> = ({
  familyId,
  event,
  handleClose,
}, _ref) => {
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
  const [ updateEventModalOpen, setUpdateEventModalOpen ] = useState(false);
  const { mutate: deleteEvent, isLoading: isDeletingEvent } = useDeleteEvent(familyId);
  const currentUser = useContext(AuthorizationContext);
  const isCreator = currentUser?.data?.id === event?.creator?.id;
  const isAdminRole = isAdminRoles(currentUser?.data?.accountType);

  const onDeleteEvent = () => {
    deleteEvent(event?.id, {
      onSuccess: () => {
        setDeleteModalOpen(false);
        queryClient.invalidateQueries(EVENTS);
        handleClose?.();
      },
    });
  };

  const onUpdate = () => {
    setUpdateEventModalOpen(false);
    handleClose?.();
  };

  return (
    <>
      <MenuItem onClick={() => setUpdateEventModalOpen(true)}>Edit</MenuItem>
      <MenuItem hidden={!(isCreator || isAdminRole)} onClick={() => setDeleteModalOpen(true)}>Delete</MenuItem>

      { updateEventModalOpen && (
        <UploadEventModal
          familyId={familyId}
          event={event}
          open={updateEventModalOpen}
          onClose={onUpdate}
        />
      ) }

      <AlertDialog
        dialogWidth="350px"
        open={deleteModalOpen}
        title="Are you sure you want to delete this event?"
        content={
          <DeleteEventContent>
            Event "{ event?.title }"
          </DeleteEventContent>
        }
        loading={isDeletingEvent}
        submitButtonDisabled={isDeletingEvent}
        handleSubmit={() => onDeleteEvent()}
        handleCancel={() => setDeleteModalOpen(false)}
      />
    </>
  );
};

export const EventMenuItems = forwardRef(EventMenuItemsBase);
