import { FC, useMemo }                 from 'react';
import { Button, Grid }                from '@material-ui/core';
import { FastField, useFormikContext } from 'formik';
import { UploadEntityFormSpacing }     from '../../../../shared/components/documents/styles';
import { GridDivider }                 from '../../../../shared/components/layout/GridDivider';
import { SelectFamily }                from '../../../../shared/components/SelectFamily/SelectFamily';
import { FamilyMembersSelector }       from '../../../../shared/formFields/FamilyMembersSelector';
import { TextInputField }              from '../../../../shared/formFields/TextInputField';
import { FlexVerticalCenterGrid }      from '../../../../shared/styledComponents';
import { DetailsPageSpacedLabel }      from '../../../MyFamilyCore/shared/styles';
import { SendMessageForm }             from '../SendMessageForm';
import { ICreateChatRoomRequest }      from './validation';
import { LoadingOverlay }              from '../../../../shared/components/LoadingOverlay';

interface IAddContactModalFormProps {
  familyId?: string;
  loading?: boolean;
  sendingMessage: boolean;
  onSendMessage(evt: any): void;
}

export const CreateChatRoomModalForm: FC<IAddContactModalFormProps> = ({
  loading,
  familyId,
  sendingMessage,
  onSendMessage,
}) => {
  const { values, isValid, isSubmitting, setFieldValue } = useFormikContext<ICreateChatRoomRequest>();
  const message = values?.initialMessage;
  const setMessage = (msg: string) => setFieldValue('initialMessage', msg);

  return useMemo(() => (
    <UploadEntityFormSpacing>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <FastField
            label="Chat Name"
            name="roomName"
            inputProps={{ maxLength: 100 }}
            component={TextInputField}
          />
        </Grid>

        <GridDivider margin="5px 0 -5px" />

        <FlexVerticalCenterGrid
          item
          xs={12}
        >
          <DetailsPageSpacedLabel>Select Family</DetailsPageSpacedLabel>

          <SelectFamily />
        </FlexVerticalCenterGrid>

        <FlexVerticalCenterGrid
          item
          xs={12}
        >
          <FamilyMembersSelector
            multiple
            onlyAccepted
            name="familyUsers"
            filterFunction={members => members.filter((member) => member.chatAccess)}
          />
        </FlexVerticalCenterGrid>

        <GridDivider margin="0" />

        <Grid
          item
          xs={12}
        >
          { values?.roomId ? (
            <Button
              fullWidth
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              disabled={loading}
            >
              Save Changes
            </Button>
          ) : (
            <SendMessageForm
              inner
              fixed
              isValid={isValid}
              isSubmitting={isSubmitting}
              message={message}
              setMessage={setMessage}
              sendingMessage={sendingMessage}
              onSendMessage={onSendMessage}
            />
          ) }
        </Grid>
      </Grid>
      <LoadingOverlay loading={loading || false} />
    </UploadEntityFormSpacing>
  ), [ message, familyId, isValid, isSubmitting ]);
};
