import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as IconAddress } from '../../../../images/icons/icon-address.svg';
import { ReactComponent as IconEmail } from '../../../../images/icons/icon-email.svg';
import { ReactComponent as IconNotes } from '../../../../images/icons/icon-notes.svg';
import { ReactComponent as IconPhone } from '../../../../images/icons/icon-phone-number.svg';
import { Layout } from '../../../Layout/Layout';
import { GridDivider } from '../../../shared/components/layout/GridDivider';
import { useFetchContactDetails } from '../../../shared/components/modals/UploadContact/queries';
import { NavigateBack } from '../../../shared/components/NavigateBack';
import { useFetchSelectedFamily } from '../../../shared/components/SelectFamily/queries';
import { EAppRoutes } from '../../../shared/constants/constants';
import { filterMembersById } from '../../../shared/functions';
import {
  useFetchFamilyMembers,
  useFetchFamilyUser,
} from '../../../shared/queries/family';
import {
  FlexEndGrid,
  FlexVerticalCenterGrid,
} from '../../../shared/styledComponents';
import {
  addSpaceBeforeCapitals,
  fullName,
  formatPhoneNumber,
} from '../../../shared/utils/commonFunctions';
import { IFamilyMember } from '../../../shared/utils/withAuthorization/withAuthorization';
import {
  ActivityRecordPreview,
  RecordMessage,
} from '../../Activity/ActivityRecordPreview';
import { FamilyMembersList } from '../../Dashboard/components/FamilyMembers/FamilyMembersList';
import {
  DetailsPageCreatedDate,
  DetailsPageFooter,
  DetailsPageSpacedLabel,
  SummaryPageWrapper,
} from '../shared/styles';
import { ContactDetailsItem } from './components/ContactDetailsItem';
import { ContactIcon } from './components/ContactIcon';
import { ContactMenuItems } from './components/ContactMenuItems';
import { ContactPills } from './components/ContactPills';

export const ContactDetailsHeader = styled.div`&& {
  display: flex;
  margin: -10px -11px 10px;

  ${RecordMessage} {
    color: #2c3f69;
    margin-bottom: 0;
    padding-left: 5px;
  }
}`;

const ContactDetailsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0 12px;
  max-width: 100%;
  oveflow: hidden;
`;

const EmailWrapper = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.up('md')} {
    margin-left: 50px;
  }
`;

export const ContactDetails: FC = () => {
  const { contactId } = useParams<{ contactId?: string }>();
  const { data: family } = useFetchSelectedFamily();
  const { data: familyMembers } = useFetchFamilyMembers(family?.id, { enabled: !!family?.id });
  const { data: contact, isFetching: loadingContact } = useFetchContactDetails(contactId);
  const { data: currentUser } = useFetchFamilyUser(family?.id);
  const displayActionsMenu = (creatorId?: string) => currentUser?.id === creatorId;
  const taggedMembers = filterMembersById(familyMembers, contact?.tagged);

  const menuItems = (
    <ContactMenuItems
      familyId={family?.id}
      contact={contact}
    />
  );

  const Page = (
    <>
      <NavigateBack
        title="My Family Core"
        to={EAppRoutes.ContactsSummary}
      />

      {contact && (
        <SummaryPageWrapper>
          <ContactDetailsHeader>
            <ActivityRecordPreview
              icon={<ContactIcon />}
              menuItems={menuItems}
              description={contact?.title || fullName(contact?.firstName, contact?.lastName)}
              showMenu={displayActionsMenu(contact?.creator?.id)}
            />
          </ContactDetailsHeader>

          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <ContactPills
                role={addSpaceBeforeCapitals(contact?.role)}
                category={contact?.category?.name}
              />
            </Grid>

            <GridDivider margin="5px 0 0" />

            <ContactDetailsRow>
              <ContactDetailsItem
                ellipsis
                icon={<IconAddress />}
                title="Office Address"
                description={contact?.officeAddress}
              />

              <ContactDetailsItem
                ellipsis
                title="Home Address"
                description={contact?.homeAddress}
              />
            </ContactDetailsRow>

            <GridDivider margin="10px 0" />

            <ContactDetailsRow>
              <ContactDetailsItem
                icon={<IconPhone />}
                title="Office Number"
                description={formatPhoneNumber(contact?.officeNumber)}
              />

              <ContactDetailsItem
                title="Home/Cell Number"
                description={formatPhoneNumber(contact?.homeNumber)}
              />

              <EmailWrapper>
                <ContactDetailsItem
                  icon={<IconEmail />}
                  title="Email"
                  description={contact?.email}
                />
              </EmailWrapper>
            </ContactDetailsRow>

            <GridDivider margin="10px 0" />

            <ContactDetailsRow>
              <ContactDetailsItem
                fullWidth
                icon={<IconNotes />}
                title="Notes"
                description={contact?.notes}
              />
            </ContactDetailsRow>
          </Grid>

          <DetailsPageFooter pt="150px">
            <Grid
              container
              spacing={3}
            >
              <FlexVerticalCenterGrid
                item
                xs={12}
                sm={6}
              >
                <DetailsPageSpacedLabel>Created By</DetailsPageSpacedLabel>

                <FamilyMembersList
                  hideAllSelection
                  data={[contact?.creator as IFamilyMember]}
                />

                <DetailsPageCreatedDate>
                  {moment(contact?.creationTime).format('MMM D, YYYY')}
                </DetailsPageCreatedDate>
              </FlexVerticalCenterGrid>

              <FlexEndGrid
                item
                xs={12}
                sm={6}
              >
                <DetailsPageSpacedLabel>Tagged</DetailsPageSpacedLabel>

                <FamilyMembersList
                  shifted
                  hideAllSelection
                  data={taggedMembers}
                />
              </FlexEndGrid>
            </Grid>
          </DetailsPageFooter>
        </SummaryPageWrapper>
      )}
    </>
  );

  return useMemo(() =>
    (<Layout loading={loadingContact}>
      {Page}
    </Layout>), [window.location.search, currentUser, contact, loadingContact, familyMembers]
  );
};
