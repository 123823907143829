import axios, { AxiosError, AxiosResponse }          from 'axios';
import qs                                            from 'qs';
import { useMutation }                               from 'react-query';
import { queryClient }                               from '../../App';
import { IErrorResponse, IFamily, IRequestResponse } from '../../Auth/shared/interfaces';
import { IRepeatOptions }                            from '../../shared/components/modals/shared';
import { IAttachment }                               from '../../shared/components/modals/UploadDocument/validation';
import { Notistack }                                 from '../../shared/components/Notistack/Notistack';
import { ICategory }                                 from '../../shared/queries/categories';
import { TCreateQueryOptions, useCreateQuery }       from '../../shared/utils/hooks/useReactQuery';
import { IFamilyMember }                             from '../../shared/utils/withAuthorization/withAuthorization';
import { ACTIVITY_COUNTERS }                         from '../Activity/queries';
import { EVENTS }                                    from '../MyFamilyCore/events/queries';

export interface IEvent extends IRepeatOptions {
  id?: string;
  title: string;
  assigned: string[];
  assignedPermission: string;
  tagged: string[];
  description: string;
  family: IFamily;
  category: ICategory;
  attachments: IAttachment[];
  creationTime: string;
  creator: IFamilyMember;
  alertInterval: number;
  allDay: boolean;
  alarmRecurs: string;
  location: string;
  color: string;
  date: string;
  eventExceptionDays: string[];
  offset?: number;

  _prevDayEventIndex?: number;
  _nextDayEventIndex?: number;
}

export interface IScheduleEvent {
  events: IEvent[];
  totalCount: number;
}

export const SCHEDULE = 'SCHEDULE';
export const DELETE_EVENT = 'DELETE_EVENT';

export const useFetchSchedulePage = (
  familyId?: string,
  assignee?: string,
  month?: number,
  year?: number,
  options?: TCreateQueryOptions<IEvent[]>
) => {
  const params = {
    familyId,
    assignee,
    month,
    year,
  };

  return useCreateQuery<any>({
    queryKey : [ SCHEDULE, familyId, assignee, month, year ],
    apiUrl   : `/api/v1/events/scheduler?${ qs.stringify(params) }`,
    options  : {
      refetchOnWindowFocus : false,
      enabled              : !!familyId,
      ...options,
    },
  });
};

export const deleteEvent = (
  eventId?: string,
  familyId?: string,
) =>
  axios.delete(`/api/v1/events/${ eventId }?${ qs.stringify({ familyId }) }`)
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<{ message: string }>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

export const useDeleteEvent = (
  familyId?: string,
) => useMutation(
  DELETE_EVENT,
  (eventId?: string) => deleteEvent(eventId, familyId),
  {
    onSuccess: () => {
      Notistack.enqueueSnackbar('Event was successfully deleted', 'success');
      queryClient.invalidateQueries([ EVENTS ]);
      queryClient.invalidateQueries([ SCHEDULE ]);
      queryClient.invalidateQueries([ ACTIVITY_COUNTERS ]);
    },
    onError: (error: AxiosError<IErrorResponse>) => {
      Notistack.enqueueSnackbar(error?.response?.data?.ErrorDetails?.Message, 'error');
    },
  }
);

export const useFetchEventById = (eventId?: string) => useCreateQuery<IEvent>({
  queryKey : [ EVENTS, eventId ],
  apiUrl   : `/api/v1/events/${ eventId }`,
  options  : {
    enabled              : !!eventId,
    refetchOnWindowFocus : false,
  },
});

const fetchGoogleOAuthRedirect = () =>
  axios
    .get('/api/v1/GoogleOAuth/redirect')
    .then(({ data: { data } }: AxiosResponse<IRequestResponse<string>>) => data)
    .catch((error: IErrorResponse) => {
      throw error;
    });

// Хук для вызова мутации
export const useFetchGoogleOAuthRedirect = () =>
  useMutation(
    'FETCH_GOOGLE_OAUTH_REDIRECT',
    () => fetchGoogleOAuthRedirect(),
    {
      onError: (error: AxiosError<IErrorResponse>) => {
        Notistack.enqueueSnackbar(error?.response?.data?.ErrorDetails?.Message, 'error');
      },
    }
  );
